import "./styles.css";
import PartySocket from "partysocket";
declare const PARTYKIT_HOST: string;

const socket = new PartySocket({
  host: PARTYKIT_HOST,
  room: "user",
});

socket.addEventListener("message", (event) => {
  const p = document.createElement("p");
  p.innerText = event.data;
  document.body.appendChild(p);
});

// Create Button 1
const button1 = document.createElement("button");
button1.textContent = "push";
button1.addEventListener("click", () => {
  socket.send("push");
});

// Create Button 2
const button2 = document.createElement("button");
button2.textContent = "get";
button2.addEventListener("click", () => {
  socket.send("get");
});

// Append buttons to the body
document.body.appendChild(button1);
document.body.appendChild(button2);

const mainSocket = new PartySocket({
  host: PARTYKIT_HOST,
  room: "test",
});

mainSocket.addEventListener("message", (event) => {
  const p = document.createElement("p");
  p.innerText = `m ` + event.data;
  document.body.appendChild(p);
});

// Create Button 3
const button3 = document.createElement("button");
button3.textContent = "reaction - push";
button3.addEventListener("click", () => {
  mainSocket.send("push");
});

// Create Button 4
const button4 = document.createElement("button");
button4.textContent = "reaction - get";
button4.addEventListener("click", () => {
  mainSocket.send("get");
});

// Append buttons to the body
document.body.appendChild(button3);
document.body.appendChild(button4);
